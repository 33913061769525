<template>
    <div class="login_content" :style="{ height: height + 'px' }">
      <div class="login_banzhuan"></div>
      <div class="login-container">
        <el-form
          label-position="left"
          label-width="0px"
          class="demo-ruleForm login-page"
        >
          <h4 class="title">威视科技-公司登录</h4>
          <br />
          <el-form-item prop="username">
            <el-input
              type="text"
              v-model="AccountModel.Account"
              auto-complete="off"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-on:keyup.enter="login"
              v-model="AccountModel.Password"
              auto-complete="off"
              placeholder="密码"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item style="width: 100%">
            <el-button
              type="primary"
              style="width: 100%; height: 40px"
              @click="login"
              >登录</el-button
            >
          </el-form-item>
          <br />
        </el-form>
      </div>
      <div class="bottom">
        <div style="width: 200px" @click="ToPlatform">©RepairNote Powered by 威视科技 - VisionTec</div>
      </div>
    </div>
  </template>
  <script >
  import axios from "../../commons/AxiosMethod.js";
  
  import { ElMessageBox, ElMessage } from "element-plus";
  import ProductItem from "../../components/ProductItem.vue";
  
  export default {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data() {
      return {
        height: window.innerHeight,
        width: window.innerWidth,
        WebConfigs: {},
        AccountModel: {
          Account: "",
          Password: "",
          Checked: false,
          Code: "",
          AccountRole: [],
        },
      };
    },
    name: "App",
    mounted() {
 
    },
    components: {
      ProductItem,
    },
    methods: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      login() {
        axios.apiMethod(
          "/Companys/Identity/Login",
          "post",
          this.AccountModel,
          (s) => {
            if (s.IsSuccess) {
              localStorage.setItem("Authentication_Header", "Bearer " + s.Token);
              localStorage.setItem("User_Name", s.Name);
              localStorage.setItem("User_Image", s.Image); 
              localStorage.setItem("UserInfo", JSON.stringify(s.UserInfo));
  
              this.$router.push({
                path: "companyHome",
              });
            } else {
              ElMessageBox({
                title: "提示",
                message: s.Msg,
                type: "error",
              });
            }
          },
          (e) => {}
        );
      },
      ToPlatform() {
        this.$router.push({
          path: "adminlogin",
        });
      },
    },
  };
  </script>
  
  
  <style lang="less" scoped>
  .login_content {
    width: 100%;
    background-image: url("../../assets/images/storeAmind/storeLogin-back.png");
    background-size: cover;
    background-position: 50% 50%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .login_banzhuan {
      background-image: url("../../assets/images/storeAmind/guanlilogin.png");
      background-repeat: inherit; 
      width: 800px;
      height: 600px;
      left: 200px;
      top: 200px;
      background-size:800px 600px;
      background-position: 50% 50%;
      text-align: center;
    }
  
    .login-container {
      width: 600px;
      height: 400px;
  
      .login-page {
        -webkit-border-radius: 5px;
        width: 400px;
        border-radius: 5px;
        margin: 0 auto;
        padding: 35px 35px 15px;
        background: #fff;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 25px #cac6c6;
      }
    }
  }
  
  label.el-checkbox.rememberme {
    margin: 0px 0px 15px;
    text-align: left;
  }
  
  .bottom {
    position: absolute;
    bottom: 5px;
    text-align: center;
    width: 80%;
  }
  </style>
  